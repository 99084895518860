import {FC, useEffect} from 'react'
import ClientPortal from 'src/app/hooks/portal-hook'
import {cn} from 'src/app/utils/cn-utils'
import {useModal} from './Modal.hooks'
import {modalTheme} from './Modal.theme'
import {GModalProps} from './Modal.types'

export const GModal: FC<GModalProps> = ({children, show, onHide, size = 'md', className}) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (show && event.key === 'Escape') {
        if (onHide) {
          onHide(event as unknown as React.MouseEvent)
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [onHide, show])

  const {temporaryShow, animateClose} = useModal(show)
  if (!temporaryShow) return null

  return (
    <ClientPortal wrapperId='modal-portal'>
      <div
        data-testid='gmodal-test'
        className='fixed top-0 right-0 left-0 bottom-0 z-[9999] flex items-center justify-center'
      >
        <div
          data-testid='gmodal-test-backdrop'
          onClick={onHide}
          className='absolute top-0 bottom-0 left-0 right-0 bg-black/50'
        ></div>
        <div className='flex items-center justify-center'>
          <div
            className={cn(modalTheme({size, className}), 'animate-modal-open', {
              'animate-modal-close': animateClose,
            })}
          >
            {children}
          </div>
        </div>
      </div>
    </ClientPortal>
  )
}
