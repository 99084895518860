import axios from 'axios'
import {BaseResponse, Pagination, QueryModel, Response} from 'src/app/models/api.types'
import {
  ISaveCompanyRole,
  ISaveRole,
  Role,
  RoleAccessTemplate,
  RoleDetail,
} from '../models/Role.model'
import {PermissionQuery, User, UserQuery} from '../models/User.model'
import {getOptionsWithSelectAll} from 'src/app/utils/select-all-utils'

// API URL
export const USER = '/api/users'
export const ROLE = '/api/v1/admins/user-roles'
export const USER_ROLES = '/api/user-roles'
export const ROLE_ACCESS_TEMPLATE = '/api/v1/admins/user-roles/access-template'
export const ROLE_PERMISSION = '/api/permission'
export const POS_USER_ROLE = '/api/pos-user-roles'

// REACT QUERY KEY
export const LIST_USER_KEY = 'list-user'
export const DETAIL_USER_KEY = 'detail-user'
export const LIST_ROLE_KEY = 'list-role'
export const DETAIL_ROLE_KEY = 'detail-role'
export const ACCESS_TEMPLATE = 'access-template'

// User API
export const getListUser = (params?: UserQuery) => {
  return axios.get<BaseResponse<Pagination<User[]>>>(USER, {
    params,
  })
}

export const getDetailUser = (id?: string) => {
  return axios.get<BaseResponse<Response<User>>>(`${USER}/${id}`)
}

export const saveUser = (data?: User, id?: string) => {
  if (id) return axios.put(`${USER}/${id}`, data)

  return axios.post(USER, data)
}

export const deleteUser = (id?: string, data?: any) => {
  return axios.delete(`${USER}/${id}`, {
    data,
  })
}

export const verificationUserToken = (data?: any) => {
  return axios.post(`${USER}/verification-token`, data)
}

export const resetPasswordUser = (data?: any, headers?: any) => {
  return axios.post(`${USER}/reset-password`, data, {
    headers,
  })
}

export const forgotPassword = (data?: any, headers?: any) => {
  return axios.post(`${USER}/forgot-password`, data, {
    headers,
  })
}

export const getProfile = () => {
  return axios.get<BaseResponse<Response<User>>>(`${USER}/my-profile`)
}

// Role API

export const getListRole = (params?: QueryModel) => {
  return axios.get<BaseResponse<Pagination<Role[]>>>(USER_ROLES, {
    params,
  })
}

export const getDetailRole = (id?: string) => {
  return axios.get<BaseResponse<Response<RoleDetail>>>(`${ROLE}/${id}`)
}

export const saveRole = (data?: ISaveRole, id?: string) => {
  if (id) return axios.put(`${ROLE}/${id}`, data)

  return axios.post(ROLE, data)
}

export const savePosUserRole = (data?: ISaveCompanyRole, id?: string) => {
  if (id) return axios.put(`${POS_USER_ROLE}/${id}`, data)

  return axios.post(POS_USER_ROLE, data)
}

export const deleteRole = (id?: string) => {
  return axios.delete(`${USER_ROLES}/${id}`)
}

export const getListAccessTemplateRole = () => {
  return axios.get<BaseResponse<Response<RoleAccessTemplate[]>>>(ROLE)
}

export const getListPermission = (params: PermissionQuery) => {
  return axios.get<BaseResponse<Pagination<RoleAccessTemplate[]>>>(ROLE_PERMISSION, {
    params,
  })
}

export const listOptionsType = async (
  search: string,
  loadedOptions: any,
  withSelectAll: boolean = false,
  render?: {label?: string; value?: string}
) => {
  try {
    const payload = await getListRole({
      page: Math.ceil(loadedOptions.length / 10),
      size: 10,
      search,
    })

    const data = payload.data.response_output?.list?.content ?? []

    let options = data?.map((item: any) => ({
      label: render?.label ? item[render.label] : item.type,
      value: render?.value ? item[render.value] : item.type,
    }))

    const optionWithSelectAll = getOptionsWithSelectAll(options)

    return {
      options: withSelectAll ? optionWithSelectAll : options,
      hasMore:
        options.length + loadedOptions.length <
        (payload.data.response_output?.list?.pagination?.total ?? 0),
    }
  } catch (e) {
    return {options: [], hasMore: false}
  }
}

export const listOptionsRoleReference = async (
  search: string,
  loadedOptions: any,
  withSelectAll: boolean = false,
  render?: {label?: string; value?: string}
) => {
  try {
    const payload = await getListRole({
      page: Math.ceil(loadedOptions.length / 10),
      size: 10,
      name: search,
    })

    const data = payload.data.response_output?.list?.content ?? []

    let options = data?.map((item: any) => ({
      label: render?.label ? item[render.label] : item.name,
      value: render?.value ? item[render.value] : item.id,
      rowData: item,
    }))

    const optionWithSelectAll = getOptionsWithSelectAll(options)

    return {
      options: withSelectAll ? optionWithSelectAll : options,
      hasMore:
        options.length + loadedOptions.length <
        (payload.data.response_output?.list?.pagination?.total ?? 0),
    }
  } catch (e) {
    return {options: [], hasMore: false}
  }
}
