import {FC, MouseEventHandler} from 'react'
import {GButton} from '../../Libs/Button/Button.component'

interface ModalFooterProps {
  handleSuccess?: MouseEventHandler
  handleClose?: MouseEventHandler
  positiveLabel?: string
  negativeLabel?: string
  loading?: boolean
  disabled?: boolean
  disabledBtnNegative?: boolean
  disabledBtnPositive?: boolean
}

const ModalFooter: FC<ModalFooterProps> = ({
  handleSuccess,
  handleClose,
  positiveLabel,
  negativeLabel,
  loading,
  disabled,
  disabledBtnNegative,
  disabledBtnPositive,
}) => {
  return (
    <div className='flex items-center justify-end w-full px-6 py-5 border-t gap-x-3 border-neutral-30'>
      {!disabledBtnPositive && (
        <GButton
          onClick={handleClose}
          type='button'
          variant='OUTLINED'
          className='min-w-[100px] text-sm font-medium text-black border-[#D0D5DD]'
          disabled={loading}
          data-testid='close-button'
          data-cy='close-button'
        >
          {negativeLabel ?? 'No'}
        </GButton>
      )}
      {!disabledBtnNegative && (
        <GButton
          onClick={handleSuccess}
          className='min-w-[100px] text-sm font-medium'
          type='button'
          loading={loading}
          disabled={loading || disabled}
          data-testid='success-button'
          data-cy='success-button'
        >
          {positiveLabel ?? 'Yes'}
        </GButton>
      )}
    </div>
  )
}

export default ModalFooter
