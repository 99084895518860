import {FC, MouseEventHandler, ReactNode} from 'react'
import {GButton, GModal} from '../../Libs'
import GIcon from '../../Libs/Icon/GIcon'

interface WarningModalProps {
  show: boolean
  handleSuccess: MouseEventHandler
  handleClose: MouseEventHandler
  header: string
  children?: ReactNode
  positiveLabel?: string
  negativeLabel?: string
  size?: 'xl' | 'lg' | 'md' | 'sm'
  loading?: boolean
  isBtnCloseHidden?: boolean
  isFooterHidden?: boolean
}

const WarningModal: FC<WarningModalProps> = ({
  show,
  handleSuccess,
  handleClose,
  header,
  positiveLabel,
  negativeLabel,
  size = 'sm',
  loading,
  children,
}) => {
  return (
    <GModal show={show} onHide={handleClose} size={size} className='p-6 rounded-xl'>
      <div className='mb-5 '>
        <GIcon icon='IconWarningCircle' className='w-12 h-12' />
      </div>
      <div className='mb-8'>
        <div className='w-full font-medium text-neutral-100 text-fs-7'>{header}</div>
        <div className='w-full text-neutral-70 text-fs-9'>{children}</div>
      </div>
      <div className='flex items-center justify-between w-full gap-4'>
        <GButton
          onClick={handleClose}
          type='button'
          variant='OUTLINED'
          className='w-full text-sm font-medium text-black border-black'
          disabled={loading}
          data-testid='close-button'
          size={'large'}
          data-cy='close-button'
        >
          {negativeLabel ?? 'No'}
        </GButton>

        <GButton
          onClick={handleSuccess}
          className='w-full text-sm font-medium'
          type='button'
          loading={loading}
          disabled={loading}
          data-testid='success-button'
          size={'large'}
          data-cy='success-button'
        >
          {positiveLabel ?? 'Yes'}
        </GButton>
      </div>
    </GModal>
  )
}

export default WarningModal
