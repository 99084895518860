export const Wording = {
  Button: {
    Save: 'Save',
    Cancel: 'Cancel',
    Delete: 'Delete',
    Back: 'Back',
    Add: 'Add',
    Yes: 'Exit',
    Stay: 'Stay',
    Ok: 'Ok',
    Sure: 'Sure',
    AddUser: 'Add User',
  },
  Status: {
    Active: 'Active',
    Inactive: 'Inactive',
  },
  Gender: {
    Male: 'Male',
  },
  BulkAddStation: {
    DownloadTemplateInstruction:
      'You can fill the content on this feature into template file. Make sure you save again using format excel file.',
    UploadFileInstruction: 'Choose or put Excel file (.xls/.xlsx) at here.',
  },
}

export const SpreadsheetFileFormat = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]
