import {FC} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {usePageData} from 'src/app/context/PageDataContext'
import {cn} from 'src/app/utils/cn-utils'
import {trimBreadcrumbs} from './Breadcrumbs.utils'
import GIcon from '../Icon/GIcon'

const Separator: FC = () => {
  return (
    <span className='flex items-center justify-center w-5 h-5 rounded-full shrink-0 text-neutral-70'>
      <GIcon icon='IconChevronRight' className='w-[6px] text-neutral-70' />
    </span>
  )
}

export const GBreadcrumbs: FC = () => {
  const {pageTitle, pageDescription, pageBreadcrumbs} = usePageData()
  const history = useHistory()

  return (
    <div>
      {(pageDescription || (pageBreadcrumbs && pageBreadcrumbs.length > 0)) && (
        <ul className='flex flex-wrap items-center p-0 m-0 list-none text-fs-10'>
          {Array.from(pageBreadcrumbs ?? []).map((item, index) => (
            <li
              className={cn('flex items-center pr-1', {
                'text-neutral-80 dark:text-neutral-30': !item.isSeparator && item.isActive,
                'text-neutral-70 dark:text-neutral-20': !item.isSeparator && !item.isActive,
              })}
              key={String(index)}
            >
              {!item.isSeparator ? (
                <Link
                  className='transition-all duration-200 text-neutral-70 hover:text-neutral-80'
                  to={item.path}
                  onClick={() => {
                    history.push(item.path)
                  }}
                >
                  {trimBreadcrumbs(item.title)}
                </Link>
              ) : (
                <Separator />
              )}
            </li>
          ))}
          <li className='flex items-center text-neutral-80 dark:text-neutral-20'>
            {trimBreadcrumbs(pageDescription ?? pageTitle)}
          </li>
        </ul>
      )}
    </div>
  )
}
