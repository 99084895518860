import {FC} from 'react'
import {cn} from 'src/app/utils/cn-utils'
import {FormLabelProps} from './Label.types'
import GIcon from '../../../Icon/GIcon'

export const FormLabel: FC<FormLabelProps> = ({
  children,
  required,
  optional,
  description,
  className,
  appendIconLabel,
  tooltipText
}) => {
  return (
    <div className={cn('flex flex-col', className)} data-testid='form-label-container'>
      <label className='flex-grow pb-1 font-medium text-fs-9 text-neutral-80 flex gap-x-1 items-center'>
        {children}
        {appendIconLabel}
        {required && <span className=' text-danger'>*</span>}
        {optional && <span className=' text-neutral-70'>(Opsional)</span>}
        {tooltipText && (
        <div className="relative flex items-center group">
          <GIcon icon="IconQuestion" />
          <span className="absolute left-full ml-2 bg-black text-white text-xs p-2 rounded w-52 opacity-0 group-hover:opacity-90 transition">
            {tooltipText}
          </span>
        </div>
      )}
      </label>

      {description && (
        <div className='flex items-center pb-1 mt-1'>
          <label className='text-fs-10 text-neutral-70'>{description}</label>
        </div>
      )}
    </div>
  )
}
