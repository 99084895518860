import {FC} from 'react'
import en from 'react-date-object/locales/gregorian_en'
import DatePicker from 'react-multi-date-picker'
import TimePicker from 'react-multi-date-picker/plugins/time_picker'
import {FormText} from '../Text'
import {FormDateProps} from './Datepicker.types'

export const FormTimepicker: FC<FormDateProps> = ({
  placeholder = 'Enter date',
  error,
  touched,
  format = 'DD-MM-YYYY',
  showTimePicker = false,
  className,
  disabled,
  isAppendInnerIcon,
  disableDayPicker = false,
  ...rest
}) => {
  return (
    <DatePicker
      portal
      zIndex={20000}
      containerStyle={{
        width: '100%',
      }}
      render={
        <FormText
          className='w-full'
          readOnly
          prependInnerIconRight='IconClock'
          placeholder={placeholder}
          error={error}
          touched={touched}
          disabled={disabled}
        />
      }
      disableDayPicker
      format='HH:mm'
      plugins={[<TimePicker hideSeconds position='bottom' key={'start'} />]}
      showOtherDays
      className='custom-calendar'
      monthYearSeparator=' '
      locale={en}
      {...rest}
    />
  )
}
