import {FC, MouseEventHandler, ReactNode} from 'react'
import {GModal} from '../../Libs'
import ModalFooter from './ModalFooter'
import ModalHeader from './ModalHeader'

interface BasicModalProps {
  show: boolean
  handleSuccess: MouseEventHandler
  handleClose: MouseEventHandler
  header: string
  children?: ReactNode
  positiveLabel?: string
  negativeLabel?: string
  size?: 'xl' | 'lg' | 'md' | 'sm'
  loading?: boolean
  isBtnCloseHidden?: boolean
  isFooterHidden?: boolean
  className?: string
  disabled?: boolean
  disabledBtnNegative?: boolean
  disabledBtnPositive?: boolean
  staticClose?: boolean
}

const BasicModal: FC<BasicModalProps> = ({
  show,
  handleSuccess,
  handleClose,
  header,
  positiveLabel,
  negativeLabel,
  size = 'md',
  loading,
  children,
  isBtnCloseHidden,
  isFooterHidden,
  className,
  disabled,
  disabledBtnNegative,
  disabledBtnPositive,
  staticClose = false,
}) => {
  return (
    <GModal show={show} onHide={staticClose ? undefined : handleClose} size={size}>
      <ModalHeader header={header} handleClose={handleClose} isBtnCloseHidden={isBtnCloseHidden} />
      <div className={`px-6 py-5 min-h-[100px] text-neutral-80 w-full ${className}`}>
        {children}
      </div>
      {!isFooterHidden && (
        <ModalFooter
          handleClose={handleClose}
          handleSuccess={handleSuccess}
          positiveLabel={positiveLabel}
          negativeLabel={negativeLabel}
          loading={loading}
          disabled={disabled}
          disabledBtnNegative={disabledBtnNegative}
          disabledBtnPositive={disabledBtnPositive}
        />
      )}
    </GModal>
  )
}

export default BasicModal
