import {useMutation} from '@tanstack/react-query'
import {useFormik} from 'formik'
import {FC, useState} from 'react'
import {useDispatch} from 'react-redux'
import {FormPassword, FormText, GButton} from 'src/app/components/Libs'
import {useWebTitle} from 'src/app/hooks/title-hook'
import * as Yup from 'yup'
import ModalConfirm from '../../../../components/Widgets/ModalCollection/ModalConfirm'
import WarningToast from '../../components/WarningToast'
import AuthRedux from '../../redux/AuthRedux'
import {loginByEmail} from '../../services/Auth.services'

const loginSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
})

const initialValues = {
  username: '',
  password: '',
}

const LoginEmail: FC = () => {
  const dispatch = useDispatch()
  useWebTitle('Login')
  const [payloadValue, setPayloadValue] = useState(initialValues)

  const [isDivice, setIsDivice] = useState(false)
  const [isWarning, setIsWarning] = useState({
    shown: false,
    scheme: 'warning',
    header: 'Invalid Username or Password',
    text: 'Please check again either username or password!',
  })

  const submitFn = useMutation({
    mutationFn: (payload: typeof initialValues) => {
      const newPayload = {
        ...payload,
      }

      return loginByEmail(newPayload)
    },
    onSuccess: (result) => {
      const data = result.data.response_output.detail
      const {access_token, refresh_token} = data

      dispatch(AuthRedux.actions.login(access_token, refresh_token))
    },
    onError: (e: any) => {
      const status = e.response.status
      setIsDivice(false)

      if (status >= 500) {
        setIsWarning({
          scheme: 'warning',
          shown: true,
          header: 'Service Temporarily Unavailable',
          text: 'Please try again later.',
        })
      } else if (status === 403) {
        setIsDivice(true)
      } else {
        setIsWarning({
          scheme: 'warning',
          shown: true,
          header: 'Invalid Username or Password',
          text: 'Please check again either username or password!',
        })
      }

      setTimeout(() => {
        setIsWarning({
          ...isWarning,
          shown: false,
        })
      }, 3000)
    },
  })

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      const params = {
        ...values,
        force: false,
      }
      submitFn.mutate(params)
      // setIsDivice(true)
      setPayloadValue(values)
    },
  })

  return (
    <div data-testid='login-email-page' className='w-full'>
      <WarningToast data={isWarning} />

      <form className='w-full' onSubmit={formik.handleSubmit} noValidate data-testid='form-login'>
        <div className='mb-8'>
          <FormText
            data-cy='login-username'
            {...formik.getFieldProps('username')}
            prependInnerIcon='IconUsers'
            name='username'
            type='text'
            placeholder='Username'
            maxLength={50}
            error={formik.errors.username}
            touched={formik.touched.username}
          />
        </div>

        <div className='mb-8'>
          <FormPassword
            data-cy='login-password'
            {...formik.getFieldProps('password')}
            prependInnerIcon='IconLock'
            name='password'
            placeholder='Password'
            minLength={8}
            error={formik.errors.password}
            touched={formik.touched.password}
          />
        </div>

        <GButton
          type='submit'
          size='large'
          className='w-full border-none bg-danger'
          loading={submitFn.isLoading}
          disabled={submitFn.isLoading}
        >
          Sign In
        </GButton>
      </form>

      <ModalConfirm
        show={isDivice}
        handleClose={() => setIsDivice(false)}
        handleSuccess={() => {
          const payload = {
            ...payloadValue,
            force: true,
          }
          submitFn.mutate(payload)
        }}
        header='User Logged in, in another device.'
        subHeader='Are you sure want to continue?'
        size='sm'
        loading={submitFn.isLoading}
      />
    </div>
  )
}

export default LoginEmail
