import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const POSUsersScreen = {
  POS_USER: {
    PATH: '/master-data/pos-user',
    TITLE: ' User',
    TITLE_ALT: 'User',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_USER_ADD: {
    PATH: '/master-data/pos-user/add',
    TITLE: ' Add User ',
    TITLE_ALT: 'Add User',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_USER_EDIT: {
    PATH: '/master-data/pos-user/edit/:id',
    TITLE: ' Edit User ',
    TITLE_ALT: 'Edit User',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_USER_DETAIL: {
    PATH: '/master-data/pos-user/detail/:id',
    TITLE: ' Detail User ',
    TITLE_ALT: 'Detail User',
    breadcrumbs: defaultBreadcrumbs,
  },
}
