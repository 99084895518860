import {FC} from 'react'
import {cn} from 'src/app/utils/cn-utils'
import {FormError} from '../FormError'
import {FormRadioProps} from './Radio.types'

const FormRadio: FC<FormRadioProps> = ({
  value,
  options,
  className,
  inputClassName,
  touched,
  error,
  checkedValue,
  changedValue,
  ...rest
}) => {
  return (
    <>
      <div
        data-testid='formradio-test'
        className={cn('w-full md:flex md:space-x-6 py-2', className)}
      >
        {options?.map((option, index) => {
          if (option.value === undefined)
            return <div key={option.value ?? index} className='flex-1'></div>

          return (
            <div key={option.value as string} className='flex items-center mb-4 md:mb-0'>
              <label className='flex items-center'>
                <input
                  type='radio'
                  checked={checkedValue === option.value}
                  onChange={() => changedValue(option.value)}
                  className={cn(
                    'cursor-pointer text-danger disabled:text-gray-200',
                    inputClassName,
                    {
                      'border-[#D0D5DD] bg-danger disabled:opacity-50':
                        checkedValue === option.value,
                    },
                    {
                      'bg-white border-[#D0D5DD] disabled:bg-gray-200':
                        checkedValue !== option.value,
                    }
                  )}
                  {...rest}
                />
                <span
                  className={cn('ml-4 text-neutral-80 text-fs-9', rest.disabled && 'text-gray-300')}
                >
                  {option.label}
                </span>
              </label>
            </div>
          )
        })}
      </div>

      {touched && error && <FormError>{error}</FormError>}
    </>
  )
}

export {FormRadio}
