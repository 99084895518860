import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const CompanyRoleScreen = {
  COMPANY_ROLE: {
    PATH: '/master-data/role',
    TITLE: 'Role List',
    TITLE_ALT: 'Role',
    breadcrumbs: defaultBreadcrumbs,
  },
  COMPANY_ROLE_ADD: {
    PATH: '/master-data/role/add',
    TITLE: 'Add Role',
    TITLE_ALT: 'Add Role',
    breadcrumbs: defaultBreadcrumbs,
  },
  COMPANY_ROLE_EDIT: {
    PATH: '/master-data/role/edit/:id',
    TITLE: 'Edit Role',
    TITLE_ALT: 'Edit Role',
    breadcrumbs: defaultBreadcrumbs,
  },
  COMPANY_ROLE_DETAIL: {
    PATH: '/master-data/role/detail/:id',
    TITLE: 'Detail Role',
    TITLE_ALT: 'Detail Role',
    breadcrumbs: defaultBreadcrumbs,
  },
}
