import {Action} from 'redux'
import storage from 'redux-persist/lib/storage'
import {persistReducer} from 'redux-persist'
import {IDetailLayoutMenu} from '../../../model/PosMenuTemplateLayout.model'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export interface IMenuTemplateLayoutState {
  data?: IDetailLayoutMenu[]
  name?: string
}

export const initialMenuTemplateLayoutState: IMenuTemplateLayoutState = {
  data: [],
  name: 'TESTING',
}

namespace MenuTemplateLayoutRedux {
  export const actionType = {
    DATA_TEMPLATE_DETAIL: 'DATA_TEMPLATE_DETAIL',
  }

  export const reducer = () =>
    persistReducer<IMenuTemplateLayoutState, ActionWithPayload<IMenuTemplateLayoutState>>(
      {
        storage,
        key: actionType.DATA_TEMPLATE_DETAIL,
        whitelist: [''],
      },
      (state: IMenuTemplateLayoutState = initialMenuTemplateLayoutState, action = {type: ''}) => {
        switch (action.type) {
          case actionType.DATA_TEMPLATE_DETAIL:
            return {
              ...state,
              data: action.payload?.data ?? state.data,
              name: action.payload?.name ?? state.name,
            }

          default:
            return state
        }
      }
    )

  export const actions = {
    saveMenuTemplateLayoutDetail: (data: any) => ({
      type: actionType.DATA_TEMPLATE_DETAIL,
      payload: {data},
    }),
  }
}

export default MenuTemplateLayoutRedux
