import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const ProductCategoryScreen = {
  PRODUCT_CATEGORY_LIST: {
    PATH: '/master-data/category',
    TITLE: 'Category',
    TITLE_ALT: 'Category',
    breadcrumbs: defaultBreadcrumbs,
  },
  PRODUCT_CATEGORY_ADD: {
    PATH: '/master-data/category/add',
    TITLE: 'Add Category',
    TITLE_ALT: 'Add Category',
    breadcrumbs: defaultBreadcrumbs,
  },
  PRODUCT_CATEGORY_EDIT: {
    PATH: '/master-data/category/edit/:id',
    TITLE: 'Edit Category',
    TITLE_ALT: 'Edit Category',
    breadcrumbs: defaultBreadcrumbs,
  },
  PRODUCT_CATEGORY_DETAIL: {
    PATH: '/master-data/category/detail/:id',
    TITLE: 'Detail Category',
    TITLE_ALT: 'Detail Category',
    breadcrumbs: defaultBreadcrumbs,
  },
}
