import {FC} from 'react'
import {cn} from 'src/app/utils/cn-utils'
import GIcon from '../../../Icon/GIcon'
import {FormError} from '../FormError'
import {FormTextProps} from './Text.types'
import clsx from 'clsx'

export const FormText: FC<FormTextProps> = ({
  errorIsRp,
  className,
  inputClassName,
  error,
  touched,
  type = 'text',
  prependIcon,
  prependInnerIcon,
  appendIcon,
  appendInnerIcon,
  prependClassname,
  prependInnerClassname,
  appendClassname,
  appendInnerClassname,
  clickPrependIcon,
  clickPrependInnerIcon,
  clickAppendIcon,
  clickAppendInnerIcon,
  prependNode,
  appendNode,
  lastSuffix,
  firstSuffixIconsClassName,
  lastSuffixIconsClassName,
  firstSuffixIcons,
  lastSuffixIcons,
  firstSuffix,
  alertInformation,
  prefix,
  suffix,
  prependInnerIconRight,
  isClearWordingError,
  maxLength,
  ...rest
}) => {
  return (
    <div className={className}>
      <div className='relative flex items-center' id='form-text'>
        {prependIcon && (
          <GIcon
            icon={prependIcon}
            className={cn('cursor-pointer mr-2 w-5 h-5', prependClassname)}
            onClick={clickPrependIcon}
          />
        )}

        {prependNode && <div className={cn('ml-2')}>{prependNode}</div>}

        <div className='relative flex items-center flex-grow' id='form-text-input'>
          {prefix && (
            <div
              data-testid='formtext-prefix'
              className='absolute w-5 h-5 font-medium left-4 text-fs-9'
            >
              {prefix}
            </div>
          )}

          {prependInnerIcon && (
            <GIcon
              icon={prependInnerIcon}
              className={cn('cursor-pointer absolute left-4 w-5 h-5', prependInnerClassname)}
              onClick={clickPrependInnerIcon}
            />
          )}

          {firstSuffixIcons && (
            <div className='inline-flex items-center h-[46px] px-4 bg-gray-200 border border-gray-300 rounded-none rounded-l-md'>
              <GIcon
                icon={firstSuffixIcons}
                className={cn('', firstSuffixIconsClassName)}
                onClick={clickPrependInnerIcon}
              />
            </div>
          )}
          {firstSuffix && (
            <span
              className={cn(
                'inline-flex items-center h-[46px] px-4 text-[1rem] text-gray-900 bg-gray-200 border border-gray-300 rounded-none rounded-l-md ',
                {
                  'border-danger': error && touched,
                }
              )}
            >
              {firstSuffix}
            </span>
          )}
          <input
            type={type}
            maxLength={maxLength}
            onWheel={() => {
              const activeElement = document.activeElement as HTMLInputElement
              activeElement && activeElement.blur()
            }}
            className={cn(
              {
                'border-danger focus:border focus:border-solid focus:border-danger':
                  touched && error,
                'pl-10': prependInnerIcon || prefix,
                'pr-14': appendInnerIcon || suffix,
                'rounded-none rounded-r-md border-l-0': firstSuffix || firstSuffixIcons,
                'rounded-none rounded-l-md border-r-0': lastSuffix || lastSuffixIcons,
                'pr-8': !appendInnerIcon && !alertInformation && !errorIsRp && touched && error,
              },
              inputClassName
            )}
            {...rest}
          />
          {!appendInnerIcon && !alertInformation && !errorIsRp && touched && error && (
            <GIcon
              icon='IconWarningDanger'
              className={clsx('cursor-pointer absolute w-5 h-5 right-2', appendInnerClassname)}
            />
          )}

          {prependInnerIconRight && (
            <GIcon
              icon={prependInnerIconRight}
              className={cn('cursor-pointer absolute right-3 w-5 h-5', prependInnerClassname)}
              onClick={clickPrependInnerIcon}
            />
          )}

          {appendInnerIcon && (
            <GIcon
              icon={appendInnerIcon}
              className={cn('cursor-pointer absolute right-4 w-5 h-5', appendInnerClassname)}
              onClick={clickAppendInnerIcon}
            />
          )}

          {suffix && (
            <div
              data-testid='formtext-suffix'
              className='absolute w-5 h-5 font-medium right-11 text-fs-9 text-[#344054]'
            >
              {suffix}
            </div>
          )}
        </div>
        {appendIcon && (
          <GIcon
            icon={appendIcon}
            className={cn('cursor-pointer ml-2 w-5 h-5', appendClassname)}
            onClick={clickAppendIcon}
          />
        )}
        {appendNode && <div className={cn('ml-2')}>{appendNode}</div>}

        {lastSuffixIcons && (
          <div className='inline-flex items-center h-[46px] px-4 bg-gray-200 border border-gray-300 rounded-none rounded-r-md'>
            <GIcon
              icon={lastSuffixIcons}
              className={cn('', lastSuffixIconsClassName)}
              onClick={clickPrependInnerIcon}
            />
          </div>
        )}
        {lastSuffix && (
          <span
            className={cn(
              'inline-flex items-center  h-[46px] px-4 text-[1rem] text-gray-900 bg-gray-200 border border-gray-300 rounded-none rounded-r-md ',
              {
                'border-danger': error && touched,
              }
            )}
          >
            {lastSuffix}
          </span>
        )}
      </div>
      {isClearWordingError === undefined && touched && error && <FormError>{error}</FormError>}
    </div>
  )
}
