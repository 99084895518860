import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const PosMenuTemplateLayoutScreen = {
  POS_MENU_TEMPLATE_LAYOUT: {
    PATH: '/master-data/menu-template-layout',
    TITLE: 'Menu Template Layout',
    TITLE_ALT: 'Menu Template Layout',
    breadcrumbs: defaultBreadcrumbs,
  },
  POS_MENU_TEMPLATE_LAYOUT_EDIT: {
    PATH: '/master-data/menu-template-layout/edit/:id',
    TITLE: 'Edit Menu Template Layout',
    TITLE_ALT: 'Edit Menu Template Layout',
    breadcrumbs: defaultBreadcrumbs,
  },
}
