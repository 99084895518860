import {FC} from 'react'
import {Tooltip} from 'react-tooltip'
import {twMerge} from 'tailwind-merge'

const tooltipStyles = {
  'style-1': 'tooltip-custom',
  'style-2': 'px-3 py-2 text-white bg-gray-800 text-xs z-[9999] rounded-lg',
  'style-3': 'update-custom',
  'style-4': 'w-[250px] text-fs-10 rounded-lg text-left p-2 z-[9999]',
}

const TooltipView: FC = () => {
  return (
    <>
      <Tooltip id='copy' className={tooltipStyles['style-1']}>
        Copy
      </Tooltip>
      <Tooltip id='view-tooltip' className={tooltipStyles['style-1']}>
        View
      </Tooltip>
      <Tooltip id='edit-tooltip' className={tooltipStyles['style-1']}>
        Edit
      </Tooltip>
      <Tooltip id='restore-tooltip' className={tooltipStyles['style-1']}>
        Restore
      </Tooltip>
      <Tooltip id='delete-tooltip' className={tooltipStyles['style-1']}>
        Delete
      </Tooltip>
      <Tooltip id='send-tooltip' className={tooltipStyles['style-1']}>
        Send
      </Tooltip>
      <Tooltip id='duplicate-tooltip' className={tooltipStyles['style-1']}>
        Duplicate
      </Tooltip>
      <Tooltip id='refresh-tooltip' className={tooltipStyles['style-1']}>
        Refresh
      </Tooltip>
      <Tooltip id='order-qty' className={tooltipStyles['style-4']}>
        This field set of max. order qty will be applied to EZO
      </Tooltip>
      <Tooltip id='update-voucher' className={tooltipStyles['style-3']}>
        Updating Voucher
      </Tooltip>
      <Tooltip id='deactivating-voucher' className={tooltipStyles['style-3']}>
        Deactivating Voucher
      </Tooltip>
      <Tooltip id='restoring-voucher' className={tooltipStyles['style-3']}>
        Restoring Voucher
      </Tooltip>
      <Tooltip id='delete-voucher' className={tooltipStyles['style-3']}>
        Deleting Voucher
      </Tooltip>

      <Tooltip
        id='table-management-tooltip'
        className={tooltipStyles['style-2']}
        classNameArrow='tooltip-descName-arrow'
      >
        You can rearrange the table by dragging it, <br />
        and if you need to make any edits, just double-click on it.
      </Tooltip>

      <Tooltip
        id='table-section-form-tooltip'
        className={tooltipStyles['style-2']}
        classNameArrow='tooltip-descName-arrow'
      >
        Features for setting prints to stations,
        <br />
        by category detail and table section
      </Tooltip>

      <Tooltip id='menu-package-tooltip' className={tooltipStyles['style-2']}>
        If set yes, Tax Calculation from each menu will be separated <br />
        from packaged head menu
      </Tooltip>
      <Tooltip id='menu-name-tooltip' className={tooltipStyles['style-2']}>
        Used when printing bills
      </Tooltip>
      <Tooltip id='inclusive_price' className={tooltipStyles['style-2']}>
        Inclusive price means the price that you set on menu template detail in included with
        service and restaurant tax
      </Tooltip>
      <Tooltip id='menu-short-name-tooltip' className={tooltipStyles['style-2']}>
        Used when displaying menu lists on POS
      </Tooltip>

      <Tooltip id='branch-type-tooltip' className={twMerge(tooltipStyles['style-2'], 'w-[210px]')}>
        No impact on operational, just to identify the branch type
      </Tooltip>
      <Tooltip
        id='external-branch-code-tooltip'
        className={twMerge(tooltipStyles['style-2'], 'w-[270px]')}
      >
        You can be used to fill in if there using open API Sales Information. e.g. branchCode,
        Manager Name, etc.
      </Tooltip>
      <Tooltip id='vat-value-tooltip' className={twMerge(tooltipStyles['style-2'], 'w-[314px]')}>
        The value comes from Master Setting VAT Value
      </Tooltip>
      <Tooltip
        id='branch-name-on-apps-tooltip'
        className={twMerge(tooltipStyles['style-2'], 'w-[307px]')}
      >
        This branch name will appear only in EZO apps
      </Tooltip>

      <Tooltip
        id='table-checker-print-date'
        className={twMerge(tooltipStyles['style-2'], 'w-[200px]')}
      >
        Information of date & time when order is submitted
      </Tooltip>
      <Tooltip
        id='table-checker-print-visit-purpose'
        className={twMerge(tooltipStyles['style-2'], 'w-[200px]')}
      >
        Information of date & time when order is submitted
      </Tooltip>
      <Tooltip
        id='table-checker-print-sales-number'
        className={twMerge(tooltipStyles['style-2'], 'w-[200px]')}
      >
        Information of Sales Number of the order
      </Tooltip>
      <Tooltip
        id='table-checker-print-order'
        className={twMerge(tooltipStyles['style-2'], 'w-[200px]')}
      >
        All detail information in the menu section
      </Tooltip>
      <Tooltip
        id='table-checker-print-waiter'
        className={twMerge(tooltipStyles['style-2'], 'w-[200px]')}
      >
        Information of the Waiter whose open the table
      </Tooltip>
      <Tooltip
        id='table-checker-print-sender'
        className={twMerge(tooltipStyles['style-2'], 'w-[200px]')}
      >
        Information of the Sender whose submit the order
      </Tooltip>
      <Tooltip
        id='table-checker-print-customer-info'
        className={twMerge(tooltipStyles['style-2'], 'w-[200px]')}
      >
        Customer Info that printed in the checker
      </Tooltip>
      <Tooltip
        id='table-checker-print-table'
        className={twMerge(tooltipStyles['style-2'], 'w-[200px]')}
      >
        Information of the table's name
      </Tooltip>

      <Tooltip
        id='kitchen-epson-sticker-width'
        className={twMerge(tooltipStyles['style-2'], 'w-[200px]')}
      >
        Setting the width for Epson Sticker
      </Tooltip>
      <Tooltip
        id='kitchen-epson-sticker-margin-left'
        className={twMerge(tooltipStyles['style-2'], 'w-[200px]')}
      >
        Setting of Left Margin for Epson Sticker
      </Tooltip>
      <Tooltip
        id='kitchen-top-margin-feed'
        className={twMerge(tooltipStyles['style-2'], 'w-[200px]')}
      >
        Setting of Top Margin for Epson Sticker
      </Tooltip>
      <Tooltip
        id='kitchen-print-move-item'
        className={twMerge(tooltipStyles['style-2'], 'w-[200px]')}
      >
        Print information if menu is moved
      </Tooltip>
      <Tooltip
        id='kitchen-print-move-table'
        className={twMerge(tooltipStyles['style-2'], 'w-[200px]')}
      >
        Print information if table is moved
      </Tooltip>
      <Tooltip
        id='kitchen-quick-service-after-payment'
        className={twMerge(tooltipStyles['style-2'], 'w-[200px]')}
      >
        Do all print after payment is submitted for Take-Away Order only
      </Tooltip>
      <Tooltip
        id='kitchen-print-table-name-quick-service'
        className={twMerge(tooltipStyles['style-2'], 'w-[200px]')}
      >
        Information of the table's name in Take-Away Order Only
      </Tooltip>
      <Tooltip id='kitchen-print-table' className={twMerge(tooltipStyles['style-2'], 'w-[200px]')}>
        Used to print table name in kitchen
      </Tooltip>
      <Tooltip
        id='kitchen-print-sales-number'
        className={twMerge(tooltipStyles['style-2'], 'w-[200px]')}
      >
        Used to print ordering sales number in kitchen printing
      </Tooltip>
      <Tooltip id='kitchen-print-batch' className={twMerge(tooltipStyles['style-2'], 'w-[200px]')}>
        Used to print ordering batch in kitchen printing
      </Tooltip>
      <Tooltip
        id='kitchen-print-table-section'
        className={twMerge(tooltipStyles['style-2'], 'w-[200px]')}
      >
        Information Sectioning of Table Management
      </Tooltip>
      <Tooltip id='kitchen-print-waiter' className={twMerge(tooltipStyles['style-2'], 'w-[200px]')}>
        Used to print waiter name in kitchen printing
      </Tooltip>
      <Tooltip id='kitchen-print-info' className={twMerge(tooltipStyles['style-2'], 'w-[200px]')}>
        Used to print info in kitchen printing
      </Tooltip>
      <Tooltip
        id='kitchen-print-sales-mode'
        className={twMerge(tooltipStyles['style-2'], 'w-[200px]')}
      >
        Used to print visit purpose / sales mode in kitchen printing
      </Tooltip>
      <Tooltip id='kitchen-print-sender' className={twMerge(tooltipStyles['style-2'], 'w-[200px]')}>
        Used to print sender in kitchen printing
      </Tooltip>
      <Tooltip id='kitchen-print-date' className={twMerge(tooltipStyles['style-2'], 'w-[200px]')}>
        Used to print ordering date in kitchen printing
      </Tooltip>

      <Tooltip id='kitchen-print-time' className={twMerge(tooltipStyles['style-2'], 'w-[200px]')}>
        Used to print ordering time in kitchen printing
      </Tooltip>
    </>
  )
}

export default TooltipView
