import {defaultBreadcrumbs} from 'src/app/models/screen-prop'

export const CompanyPOSInstallationScreen = {
  POS_INSTALLATION: {
    PATH: '/master-data/pos-intallation',
    TITLE: 'Pos Installation',
    TITLE_ALT: 'Pos Installation',
    breadcrumbs: defaultBreadcrumbs,
  },
}
